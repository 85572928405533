import React, {useState} from "react";
import ReactCardFlip from "react-card-flip";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import InfoIcon from "@mui/icons-material/Info";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const FlipCard = (props) => {
  const theme = useTheme();
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      className=""
      onClick={handleClick}
    >
      <Paper
        onClick={handleClick}
        elevation={2}
        sx={{width: 300, height: 400, borderRadius: 3}}
      >
        <Box sx={{textAlign: "center"}}>
          <Typography variant="h6" sx={{padding: 1}}>
            {props.name}
          </Typography>
          <img
            src={props.imageUrl}
            alt={props.name}
            style={{
              width: "300px",
              height: "300px",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton sx={{marginX: 1}}>
              <InfoIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>
      <Paper
        elevation={2}
        sx={{
          width: 300,
          height: 400,
          borderRadius: 3,
          bgcolor: theme.palette.primary.main,
        }}
      >
        <Box
          onClick={handleClick}
          sx={{
            padding: 2,
            height: 350,
            overflowY: "auto",
          }}
        >
          <Typography
            sx={{whiteSpace: "pre-line"}}
            color={theme.palette.common.white}
          >
            {props.biography}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: 50,
          }}
        >
          <IconButton sx={{marginX: 1}} onClick={handleClick}>
            <ChevronLeftIcon sx={{color: theme.palette.common.white}} />
          </IconButton>
          {props.cv && props.cv !== "" ? (
            <IconButton
              sx={{marginX: 2}}
              onClick={() => window.open(props.cv, "_blank")}
            >
              <Icon sx={{color: theme.palette.common.white, lineHeight: "1"}}>
                cv
              </Icon>
            </IconButton>
          ) : null}
        </Box>
      </Paper>
    </ReactCardFlip>
  );
};

export default FlipCard;
